import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { Divider } from '@pretto/zen/atoms/dividers/Divider/Divider'
import { ArrowButton } from '@pretto/zen/reveal/atoms/buttons/arrowButton/ArrowButton'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import styled, { css } from 'styled-components'

interface SlideStyleProps {
  $isActive: boolean
}

export const Title = styled.h3`
  ${({ theme }) => theme.typos.heading6};
  color: ${({ theme }) => theme.colors.neutral2};
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(6)};
  }
`

export const Description = styled.p`
  ${({ theme }) => theme.typos.body4};
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(3)};
  }
`

export const Previous = styled(ArrowButton).attrs({ direction: 'left' })`
  margin-right: ${g(4)};
  flex-shrink: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-right: ${g(7)};
  }
`

export const Next = styled(ArrowButton).attrs({ direction: 'right' })`
  margin-left: ${g(4)};
  flex-shrink: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-left: 0;
  }
`

export const SlideContainer = styled.div`
  display: flex;
  flex-basis: ${g(4 * 3 + 2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: center;
    margin-bottom: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    justify-content: flex-start;
    flex-basis: 1px;
    margin-bottom: ${g(5)};
  }
`

export const Slide = styled(Divider)<SlideStyleProps>`
  flex-basis: ${g(4)};

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      background-color: ${theme.colors.neutral1};
    `}

  & + & {
    margin-left: ${g(1)};
  }
`

export const ImageContainer = styled.div`
  position: relative;
  height: 200px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 400px;
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(7)};
    width: calc(100% - ${g(5)});
  }
`

const bounceCss = (isActive: boolean) =>
  isActive
    ? css`
        transform: translateY(-30px);
        opacity: 1;
        z-index: 5;
      `
    : css`
        transform: translateY(30px);
        opacity: 0;
        z-index: 0;
      `

export const Image = styled(BaseImage)<SlideStyleProps>`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 16px 16px 0px 0px;

  box-shadow: 0px 0px 40px rgba(5, 5, 5, 0.15);
  clip-path: inset(-100vw -100vw 0 -100vw);

  position: absolute;
  left: 0;
  right: 0;
  bottom: -45px;
  ${transition({ propertyName: 'all', duration: '800ms', easing: 'cubic-bezier(0.34, 1.8, 0.64, 1)' })};
  ${({ $isActive }) => bounceCss($isActive)};
`
