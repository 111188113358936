import Responsive from '@pretto/bricks/components/utility/Responsive'

import { useState } from 'react'

import { Laptop } from './components/Laptop/Laptop'
import { Mobile } from './components/Mobile/Mobile'
import { Tablet } from './components/Tablet/Tablet'

interface SlideProps {
  title: string
  description: string
  src: string
}

export interface SliderProps {
  slides: SlideProps[]
}

export interface SliderChildrenProps extends SliderProps {
  activeIndex: number
  onPreviousClick: () => void
  onNextClick: () => void
}

export const Slider: React.FC<SliderProps> = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleNext = () => {
    setActiveIndex(previous => previous + 1)
  }

  const handlePrevious = () => {
    setActiveIndex(previous => previous - 1)
  }
  return (
    <>
      <Responsive max="tablet">
        <Mobile slides={slides} activeIndex={activeIndex} onNextClick={handleNext} onPreviousClick={handlePrevious} />
      </Responsive>
      <Responsive min="tablet" max="laptop">
        <Tablet slides={slides} activeIndex={activeIndex} onNextClick={handleNext} onPreviousClick={handlePrevious} />
      </Responsive>
      <Responsive min="laptop">
        <Laptop slides={slides} activeIndex={activeIndex} onNextClick={handleNext} onPreviousClick={handlePrevious} />
      </Responsive>
    </>
  )
}
