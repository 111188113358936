import { g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { SliderChildrenProps } from '@pretto/website/src/templates/home/rows/rowTools/components/Slider/Slider'
import {
  Description,
  Image,
  ImageContainer,
  Next,
  Previous,
  Slide,
  SlideContainer,
  Title,
} from '@pretto/website/src/templates/home/rows/rowTools/components/Slider/Slider.styles'

import styled from 'styled-components'

export const Laptop: React.FC<SliderChildrenProps> = ({ slides, activeIndex, onPreviousClick, onNextClick }) => {
  const { title, description } = slides[activeIndex]

  return (
    <Container>
      <ImageContainer>
        {slides.map(({ src }, index) => (
          <Image key={src} path={src} $isActive={index === activeIndex} />
        ))}
      </ImageContainer>
      <Content>
        <Title>{title}</Title>
        <Text>{description}</Text>
        <SlideContainer>
          {slides.map(({ title }, index) => (
            <Slide key={title} $isActive={index === activeIndex} />
          ))}
        </SlideContainer>
        <ArrowContainer>
          <Previous disabled={activeIndex === 0} onClick={onPreviousClick} />
          <Next disabled={activeIndex === slides.length - 1} onClick={onNextClick} />
        </ArrowContainer>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  ${column([2, 14])};
  ${grid(12, { isLeftAligned: true, isRightAligned: true })}
`

const Text = styled(Description)`
  min-height: 140px;
`

const Content = styled.div`
  ${column([8, 4])};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`

const ArrowContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: ${g(8)};
`
